<script lang="ts" setup>
// Komponent odpowiedzialny za wyświetlanie dolnego paska z komunikatami.

const Cookies = defineAsyncComponent(() => import('~coreComponents/Cookies.vue'));
const CurrentMessageBar = defineAsyncComponent(() => import('~coreComponents/CurrentMessageBar.vue'));
const OfflineBar = defineAsyncComponent(() => import('~coreComponents/OfflineBar.vue'));
</script>

<template>
  <ClientOnly>
    <div class="bottom-bar">
      <Cookies />
      <CurrentMessageBar />
      <OfflineBar />
    </div>
  </ClientOnly>
</template>

<style lang="scss" scoped>
@use '~core/cds-assets/scss/helpers' as *;

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--bottom-bar-z-index);
  width: 100%;
  @include cdsBoxShadow('2xs');

  .cds-alert {
    --cds-alert-border-radius: 0;
  }
}
</style>
